.footer {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);

    &_left {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex: 1;

        &_divider {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: #FFFFFF;
            opacity: 0.2;
            margin: 0 16px;
        }

        p {
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            color: #FFFFFF;
        }
    }

    &_right {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        height: 100%;
        
        a{ 
            display: flex;
            align-items: flex-end;
        
            img {
                cursor: pointer;
                padding: 0 12px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .footer {
        padding-top: 16px;
        height: 124px;
        grid-template-columns: none;
        grid-template-rows: 1fr 1fr;
        flex-direction: column;
        gap: 12px;

        &_left {
            flex-direction: column;
            gap:10px;
            text-align: center;

            p {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
}