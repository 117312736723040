.main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    flex: 1;

    &_left {
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: flex-end;

        &_title p{
            font-style: normal;
            font-weight: 700;
            font-size: 56px;
            line-height: 72px;
            text-transform: uppercase;
            color: #FFFFFF;
            text-align: left;
            white-space: pre-line;
        }
        &_content {
            display: flex;
            gap:24px;
            &-item {
                background-color: black;
                border-radius: 16px;
                min-height: 378px;
                width: 306px;
                display: flex;
                flex-direction: column;
                align-content: center;
                padding: 24px;
                gap: 8px;
            }
        }
    }

    &_right {
        margin-left: 100px;
        overflow: hidden;
    }
}


.item {
    &_header {
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        opacity: 0.6;
    }
    &_title {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
    }
    &_description {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #FFFFFF;
        opacity: 0.6;
        white-space: pre-line;
    }
    &_btn {
        flex: 1;
        text-align: center;
        margin-top: auto 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        img {
            padding: 3px 0;
            cursor: pointer;
            object-fit: scale-down;
        }
    }
}

@media only screen and (max-width: 768px) {
    .main {
        grid-template-columns:none;
        grid-template-rows: none;
        gap: 40px;

        &_left {
            align-items: center;
            &_title p {
                font-size: 32px;
                line-height: 40px;
                text-align: center;
            }
            &_content {
                flex-direction: column;
            }
        }
        &_right {
            text-align: center;
        }
    }
}

@media only screen and (max-width: 576px) {
    .main {
        &_left {
            &_content {
                width: 100%;
                &-item {
                    width: auto;
                    margin: 0 24px;
                }
            }
        }

        &_right {
            text-align: right;
        }
    }
}