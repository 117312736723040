.header{
    position: relative;
    height: 200px;
    display: flex;
    justify-content: center;
    align-content: center;

    img {
        object-fit: scale-down;
    }
}

@media only screen and (max-width: 768px) {
    .header {
        height: 100px;
    }
}